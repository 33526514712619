import React from "react";
import ArchiveItem from "./archiveItem";

const archiveList = ({ archivs,onClickiItem }) => {
 const renderedList =  archivs.map((archiv) => {
    return <ArchiveItem key = {archiv} onItemClicked = {onClickiItem} archive = {archiv} />;
  });
  return <div> {renderedList} </div>;
};
export default archiveList;

import React from "react";
import SearchBar from "./SearchBar";
import WeimarkArchive from "../apis/weimarkArchive";
import ArchiveList from "./archiveList";
class App extends React.Component {
  state = {
    searchTerm: "",
    archiveList: [],
    archivePath: "Archive/",
    directoryLevel: 1,
  };

  //maybe can have number of directory in the state and then that will alow you to go up and down the parsed array!!

  //this function takes a archiveTerm path and calls the api and then updates the archiveList with the results
  async axiosAndStateFunction(flatten = false, archivePath = "Archive/") {
    console.log("did this axiosFunction");
    console.log("flatten is = " + flatten);
    console.log("path is = " + archivePath);

    const axios = require("axios");
    let responce = "";

    if (flatten === true) {
      responce = await axios({
        url: (process.env.REACT_APP_BASE_URL||"https://zvi-secured.weimark.com") + "/graphql/",
        method: "post",
        data: {
          query: `
      query listArchive{
        listDir(prefix:"${archivePath}",flatten:true)
        }
        `,
        },
      });
    } else {
      responce = await axios({
        url: (process.env.REACT_APP_BASE_URL||"https://zvi-secured.weimark.com") + "/graphql/",
        method: "post",
        data: {
          query: `
    query listArchive{
      listDir(prefix:"${archivePath}")
      }
      `,
        },
      });
    }
      this.setState({
        archivePath: archivePath,
        archiveList: responce.data.data.listDir,
      });
    
  }

  // componentDidUpdate() {
  //   console.log("componentDidUpdate");
  //   const aaa = this.state.archivePath.split("/");
  //   console.log(aaa);
  //   console.log(aaa[this.state.directoryLevel - 1]);
  // }

  async componentDidMount() {
    await this.axiosAndStateFunction();
  }

  ///this function will update the searchbar with a clicked archiveItem
  onArchiveItemClicked = async (item) => {
    console.log("pressed the item");
    console.log(item);
    console.log({ item });
    await this.axiosAndStateFunction(false, item);
    
    //this is to take out the correct search term from the search term path
    const splitTerm = item.split("/");
    console.log(splitTerm);
    console.log(
      splitTerm.length +
        "  this is length of array of files in splitTerm in axiosFunction"
    );
      this.setState({
        searchTerm: splitTerm[splitTerm.length - 2],
      directoryLevel: splitTerm.length - 1,
      });
  };

  ///this function is called once the user presses enter
  ///so that the search bar calls onSubmit -> onFormSubmit -> sets onParentSubmit with SearchBar.state.term argument ->tothis function
  onTermSubmit = async (term) => {
    this.setState({ searchTerm: term });
    console.log(term);
    console.log(term[0]);
    if (term[0] === undefined) {
      //if the search is empty
      await this.axiosAndStateFunction();
    } else {
      await this.axiosAndStateFunction(
        false,
        "Archive/" +
          term[0].toUpperCase() +
          "/" +
          term.slice(0, 1).toUpperCase() +
          term.slice(1, term.length)
      );
    }
  };

  render() {
    console.log("rerenderd the render() in App");
    return (
      <div className="ui container">
        <SearchBar
          onParentSubmit={this.onTermSubmit}
          currentParentTermState={this.state.searchTerm}
        />
        there were {this.state.archiveList.length} results for that search.
        <ArchiveList
          onClickiItem={this.onArchiveItemClicked}
          archivs={this.state.archiveList}
        />
      </div>
    );
  }
}
export default App;

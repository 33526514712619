import React from "react";

class SearchBar extends React.Component {
  state = { term: "" };

  componentDidUpdate(prevProps) {
    console.log(prevProps);
    // Typical usage (don't forget to compare props):
    if (this.props.currentParentTermState !== prevProps.currentParentTermState) {
      console.log(this.state.term);
      this.setState({term: this.props.currentParentTermState});
    }
  }
  onInputChange = (event) => {
    this.setState({ term: event.target.value });
  };

  ///what this function is doing is effectivly passing to the prop from the parent the state
  ///so that the parent can do something with the term written in then search bar
  onFormSubmit = (event) => {
    event.preventDefault(); 
    this.props.onParentSubmit(this.state.term)
  };

  render() {

    return (
      <div className="search-bar ui segment">
        <form className="ui form" onSubmit={this.onFormSubmit}>
          <div className="feild">
            <label>search weimark archive</label>
            <input
              type="text"
              value={this.state.term}
              onChange={this.onInputChange}
            />
          </div>
        </form>
      </div>
    );
  }
}
export default SearchBar;

import React from "react";


//class - needs to be so can use this
const archiveItem = props => {
    // ArchivClick = () => {
    //     console.log('printed this');
    //     };
    // onArchivClick = () => {
    //     console.log('printed this');
    //     };
    if (props.archive.endsWith('.pdf')){
        console.log(props.archive);
        const base_url = (process.env.REACT_APP_BASE_URL||"https://zvi-secured.weimark.com") + "/archiver/";
        const graphQL_url = base_url+ props.archive;
        return <a href = {graphQL_url}>{props.archive}</a>
    }
    const splitTerm = props.archive.split('/')
    return <div /*onChange = {ArchivClick}style="cursor:pointer"*/ onClick = {() => props.onItemClicked(props.archive)}> <a>{splitTerm[splitTerm.length -2]}</a></div>
   
}
export default archiveItem